.container {
    width: 100%;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        height: 39.375rem;
        width: 43.75rem;
        position: relative;
        border: 1px solid #101010;
        cursor: pointer;
        background: #f4f4f4;
        background-image: url(../assets/paper.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .box,
    .box::before,
    .box::after {
        /* Add shadow to distinguish sheets from one another */
        box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15);
    }

    .box::before,
    .box::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f4f4f4;
        border: 1px solid #101010;
    }

    /* Second sheet of paper */
    .box::before {
        left: 7px;
        top: 5px;
        z-index: -1;
    }

    /* Third sheet of paper */
    .box::after {
        left: 12px;
        top: 10px;
        z-index: -2;
    }

    img {
        height: 39.375rem;
        width: 100%;
        object-fit: cover;
    }

    .content {
        height: 39.375rem;
        padding: 2rem;

        .title {
            margin: 3rem auto;
            font-size: 1.5rem;
            font-weight: 500;
        }

        hr {
            height: 0;
            border: none;
            border-bottom: 1px solid #252525;
            width: 100%;
            margin: 3rem 0;
        }

        .skillset {
            line-height: 2rem;
        }
    }

}

@media only screen and (max-width: 768px) {

    .box {
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 500px) {

    .content {
        transform: scale(0.8);
        padding: 1rem !important;

        .title {
            margin: 1rem auto !important;
            font-size: 1.25rem !important;
            font-weight: 500 !important;
        }

        .skillset {
            font-size: 1rem !important;
            line-height: 2rem !important;
        }
    }

}