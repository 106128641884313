@import url("https://fonts.googleapis.com/css?family=Roboto:300,500,900|Sen:400,700,800&display=swap");

// font-family: 'Roboto', sans-serif;
// font-family: 'Sen', sans-serif;

$bodyWhite: #f4f4f4;
$bodyBlack: #101010;

html {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 17px;
        height: 17px;
        background-color: $bodyWhite;
    }

    ::-webkit-scrollbar-track {
        border: 8px solid $bodyWhite;
        background-color: #aaa;
    }

    ::-webkit-scrollbar-thumb {
        border: 8px solid $bodyWhite;
        background-color: #000;
        min-height: 24px;
        min-width: 24px;
    }

    body {
        background-color: $bodyWhite;
        color: $bodyBlack;
        width: 100%;
        height: 100%;
        font-family: "Sen", sans-serif;
        position: relative;
        overscroll-behavior-y: none;
        overscroll-behavior-x: none;

        .intro {
            width: 100vw;
            height: 100vh;
            z-index: 5;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: 400;
            font-size: 2.625rem;
            opacity: 0;
            animation: fadeIn 0.75s 0.25s ease-in forwards;
            -webkit-animation: fadeIn 0.75s 0.25s ease-in forwards;
            -moz-animation: fadeIn 0.75s 0.25s ease-in forwards;
            -o-animation: fadeIn 0.75s 0.25s ease-in forwards;
        }

        a {
            text-decoration: none;
        }

        .overlay {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: $bodyWhite;
            z-index: 98;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            a {
                display: block;
                font-size: 1.2rem;
                font-family: "Sen", sans-serif;
                font-weight: 700;
                margin: 2.5rem 0;
                color: #101010;
                animation: fadeIn 0.25s ease-in;
                -webkit-animation: fadeIn 0.25s ease-in;
                -moz-animation: fadeIn 0.25s ease-in;
                -o-animation: fadeIn 0.25s ease-in;
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }
}


@media only screen and (max-width: 414px) {
    .intro {
        font-size: 2rem !important;
    }
}