.container {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f4f4f4;

  a {
    display: block;
    font-size: 1.2rem;
    font-family: "Sen", sans-serif;
    font-weight: 700;
    margin: 0 1rem;
    color: #101010;
    opacity: 0;
  }

  .index {
    animation: fadeIn 0.25s ease-in forwards;
    -webkit-animation: fadeIn 0.25s ease-in forwards;
    -moz-animation: fadeIn 0.25s ease-in forwards;
    -o-animation: fadeIn 0.25s ease-in forwards;
  }

  .work {
    animation: fadeIn 0.25s 0.25s ease-in forwards;
    -webkit-animation: fadeIn 0.25s 0.25s ease-in forwards;
    -moz-animation: fadeIn 0.25s 0.25s ease-in forwards;
    -o-animation: fadeIn 0.25s 0.25s ease-in forwards;
  }

  .info {
    animation: fadeIn 0.25s 0.5s ease-in forwards;
    -webkit-animation: fadeIn 0.25s 0.5s ease-in forwards;
    -moz-animation: fadeIn 0.25s 0.5s ease-in forwards;
    -o-animation: fadeIn 0.25s 0.5s ease-in forwards;
  }

  .menu {
    display: none;
  }

  @keyframes fadeIn {
    from {
      transform: translateY(- 1rem);
      opacity: 0;
    }

    to {
      // transform: translateY(-100px);
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    a {
      display: none;
    }

    .menu {
      display: block;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}