.card {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80%;
  width: 80%;
  margin: 2rem;

  &:hover {
    img {
      opacity: 0.3;
      transition: all 0.25s ease-in-out;
    }

    .content {
      display: flex;
    }

    .demo {
      display: flex;
    }

    .code {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: none;
    border: 1 solid #101010;
    border-radius: 8px;
    padding: 1rem;

    .desc,
    .stack {
      font-family: 'Roboto', sans-serif;
      font-size: 1.5rem;
      font-weight: 300;
      margin: 2rem;
    }

  }



  .demo {
    background: linear-gradient(45deg, #101010 30%, #404040 90%);
    border: 0;
    font-size: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 5px 2px rgba(197, 197, 197, 0.3);
    color: white;
    height: 2.5rem;
    padding: 0 30px;
    position: absolute;
    right: 5%;
    top: calc(100% - 10rem);
    display: none
  }

  .code {
    background: linear-gradient(45deg, #404040 30%, #101010 90%);
    border: 0;
    font-size: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 5px 2px rgba(197, 197, 197, 0.3);
    color: white;
    height: 2.5rem;
    padding: 0 30px;
    position: absolute;
    right: 5%;
    top: calc(100% - 5rem);
    display: none
  }

}


@media only screen and (max-width: 1024px) {

  .desc,
  .stack {
    font-size: 1.2rem !important;
  }
}

@media only screen and (max-width: 834px) {

  .desc,
  .stack {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 768px) {

  .content {
    display: none !important;
  }

  .demo {
    transform: scale(0.8);
  }

  .code {
    transform: scale(0.8);
  }
}


@media only screen and (max-width: 414px) {

  .demo {
    transform: scale(0.5);
    right: 0 !important;
    top: calc(100% - 8rem) !important;
  }

  .code {
    transform: scale(0.5);
    right: 0 !important;
    top: calc(100% - 3rem) !important;
  }
}