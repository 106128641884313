.container {
  position: fixed;
  z-index: 97;
  top: 45%;
  right: 1rem;
  transform: translate(-10%, -50%);

  ul {
    list-style: none;
    padding: 0;
    margin-left: 1rem;

    li {
      margin: 1rem 0;

      a {
        display: block;
        font-size: 0.7rem;
        font-family: "Sen", sans-serif;
        font-weight: 700;
        color: #101010;
        opacity: 0;
      }

      .gh {
        animation: fadeIn 0.25s 0.75s ease-in forwards;
        -webkit-animation: fadeIn 0.25s 0.75s ease-in forwards;
        -moz-animation: fadeIn 0.25s 0.75s ease-in forwards;
        -o-animation: fadeIn 0.25s 0.75s ease-in forwards;
      }

      .lk {
        animation: fadeIn 0.25s 1s ease-in forwards;
        -webkit-animation: fadeIn 0.25s 1s ease-in forwards;
        -moz-animation: fadeIn 0.25s 1s ease-in forwards;
        -o-animation: fadeIn 0.25s 1s ease-in forwards;
      }

      .in {
        animation: fadeIn 0.25s 1.25s ease-in forwards;
        -webkit-animation: fadeIn 0.25s 1.25s ease-in forwards;
        -moz-animation: fadeIn 0.25s 1.25s ease-in forwards;
        -o-animation: fadeIn 0.25s 1.25s ease-in forwards;
      }
    }
  }

  @keyframes fadeIn {
    from {
      transform: translateX(1rem);
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    display: none;
  }
}